import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../api/AxiosHttp";
import Noty from "../../lib/Noty";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const email = searchParams.get("email");

  const messyToken = window.location.href.split("/")[4];
  const token = messyToken.split("?")[0];

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios.get("/sanctum/csrf-cookie").then(() => {
      axios
        .post("/api/reset-password", {
          email, token, password, password_confirmation: confirmPassword
        })
        .then((result) => {
          console.log(result);
          new Noty({
            type: "success",
            text: result.data.message,
          }).show();
          navigate("/login");
        })
        .catch(function (error) {
          if (error.response) {
            var er = error.response.data.errors;
            var notymessage = er[Object.keys(er)[0]];

            new Noty({
              type: "error",
              text: notymessage,
            }).show();
          }
        });
    });
  };

  return (
    <div className="flex flex-col md:flex-row h-screen items-center">
      <div className="bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-3/4 h-screen">
        <img
          src="https://source.unsplash.com/random"
          alt=""
          className="w-full h-full object-cover"
        />
      </div>

      <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
        <div className="w-full h-100">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/proactive-logo.svg`}
            alt="Proactive Investors"
            className="w-40"
          ></img>

          <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12">
            Create your new password
          </h1>

          <form className="mt-6" onSubmit={handleSubmit}>
            <div>
              <label className="block text-gray-700">Password</label>
              <input
                type="password"
                name=""
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                placeholder="Enter Password"
                className="w-full px-4 py-3 rounded-lg mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                autoFocus
                autoComplete="true"
                required
              />
            </div>

            <div className="mt-4">
              <label className="block text-gray-700">Confirm Password</label>
              <input
                type="password"
                name=""
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                placeholder="Enter Password"
                className="w-full px-4 py-3 rounded-lg mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                autoFocus
                autoComplete="true"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full block bg-orange-500 hover:bg-orange-400 focus:bg-orange-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
            >
              Change Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
