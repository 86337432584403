import { useState, useEffect } from 'react'
import axios from '../../api/AxiosHttp'
import { useAuthContext } from '../../context/AuthProvider'

const UserEdit = () => {

  const { activeOrganisation } = useAuthContext()

  // const getUsers = () => {
  //   if (activeOrganisation) {
  //     const endPoint = '/api/wpusers/org/' + activeOrganisation
  //     axios.get(endPoint).then(result => {
  //       console.log(result, 'GETUSERS')
  //       setUsers(result.data)
  //
  //     })
  //   }
  // }

  return (
    <div className="min-w-full text-sm align-middle whitespace-nowrap">
        <form className="space-y-6" encType="multipart/form-data" onSubmit="return false;">
            <div className="space-y-1 flex flex-col items-start">
                <label className="font-medium" htmlFor="email">Role</label>
                <input className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="email" id="email" name="email" placeholder="john.doe@example.com" />
            </div>
            <div className="space-y-1 flex flex-col items-start">
                <label className="font-medium" htmlFor="title">Password</label>
                <input className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="text" id="title" name="title" placeholder="Product Manager" />
            </div>

            <button type="submit" className="float-left inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-3 py-2 leading-5 text-sm rounded border-indigo-700 bg-indigo-700 text-white hover:text-white hover:bg-indigo-800 hover:border-indigo-800 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 active:bg-indigo-700 active:border-indigo-700">
                Update Profile
            </button>
        </form>
    </div>
  )

}

export default UserEdit
