import { useState } from "react";
import axios from "../../api/AxiosHttp";
import Paper from "@mui/material/Paper";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

import Noty from "../../lib/Noty";
import { findParentIndex } from "../../lib/Helper";

const DataTable = (props) => {

  const [rows, setRows] = useState(props.accounts);
  const columns = [
    { name: "name", title: "Name" },
    { name: "email", title: "Email" },
  ];

  return (
    <Paper>
      <Grid
        rows={rows}
        columns={columns}
      >
        <Table />
        <TableHeaderRow />
      </Grid>
    </Paper>
  );
};

export default DataTable;
