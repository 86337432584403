import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "../../api/AxiosHttp";
import Noty from "../../lib/Noty";

const AddSite = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const siteURL = searchParams.get("site_url");
  const userLogin = searchParams.get("user_login");
  const appPassword = searchParams.get("password");
  const siteName = searchParams.get("name");
  const organisationId = searchParams.get("organisation_id");

  console.log("loaded")

  const data = {
    site_url: siteURL,
    user_login: userLogin,
    password: appPassword,
    name: siteName,
    organisation_id: organisationId,
  };

  axios
    .post("/api/websites/add", { data })
    .then((result) => {
      console.log(result, "AddSite");
      new Noty({
        type: "success",
        text: result.data.message,
      }).show();
      navigate("/");
    })
    .catch((error) => {
      //TODO: test the error message is outputted
      console.log(error, "addSiteError");
      new Noty({
        type: "error",
        text: error.response.data.error,
      }).show();
      navigate("/");
    });

  return <h1>Please wait...adding website</h1>;
};

export default AddSite;