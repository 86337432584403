import { useState, useEffect, useRef } from "react";
import axios from "../../api/AxiosHttp";
import { useAuthContext } from "../../context/AuthProvider";
import UserTable from "../../components/Table/Users";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import Noty from "../../lib/Noty";

const Users = ({offBoardingNotification, offBoardingCompleteNotification}) => {
  const { activeOrganisation } = useAuthContext();

  const [users, setUsers] = useState([]);
  const [updatedKey, setUpdatedKey] = useState(Date.now());

  const counter = useRef(0);

  useEffect(() => {
    getUsers();
  }, [activeOrganisation]);

  const getUsers = () => {
    if (activeOrganisation) {
      const endPoint = "/api/wpusers/org/" + activeOrganisation;
      axios.get(endPoint).then((result) => {
        setUsers(result.data);
        setUpdatedKey(Date.now());
      });
    }
  };

  useEffect(() => {
    if (!offBoardingNotification) return;

    var type = offBoardingNotification.error ? "error" : "success";
    var text = offBoardingNotification.error
      ? offBoardingNotification.error
      : "User was successfully removed from " + offBoardingNotification.website.url;

    var offBoardingMessage = new Noty({
      type: type,
      text: text,
    }).show();

    counter.current = ++counter.current;

    new Noty({
      text:
        "Offboarding is running in the background. " +
        counter.current +
        "/" +
        offBoardingNotification.total_count,
      type: "info",
      timeout: false,
      modal: true,
      queue: "offBoarding",
      killer: "offBoarding",
      force: true,
      animation: {
        open: null,
        close: null,
      },
    }).show();
  }, [offBoardingNotification]);


  useEffect(() => {
    if (!offBoardingCompleteNotification) return;

    if (offBoardingCompleteNotification.message) {
      getUsers()

      counter.current = 0;

      setTimeout(() => {
        new Noty({
          text: offBoardingCompleteNotification.message,
          type: "success",
          timeout: 3000,
          modal: true,
          queue: "offBoarding",
          killer: "offBoarding",
          progressBar: true,
          force: true,
          animation: {
            open: null,
          },
        }).show();
      }, 3000);

    }
  }, [offBoardingCompleteNotification]);
  

  return (
    <>
      <div className="w-full">
        <Card>
          <h3 className="text-xl mb-4 font-bold leading-none text-gray-900">
            Users
          </h3>
          <p className="block text-md font-light mb-4" htmlFor="url">
            Manage all the users that are part of the websites
          </p>

          <Link
            to="/user/add"
            className="mb-6 hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center mr-3"
          >
            Onboard User
          </Link>

          {/* No longer needed as we update table with socket */}
          {/* <button
            onClick={getUsers}
            className="mb-6 hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center mr-3"
            type="button"
          >
            Refresh Users
          </button> */}

          <div className="border border-gray-200 rounded overflow-x-auto min-w-full bg-white">
            {users.length > 0 && <UserTable key={updatedKey} users={users} />}
          </div>
        </Card>
      </div>
    </>
  );
};

export default Users;
