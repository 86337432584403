import { Link } from "react-router-dom";
import { useAuthContext } from '../../context/AuthProvider'
import './Nav.scss';

const Nav = () => {

    const { userData, activeOrganisation, setActiveOrganisation, logOut } = useAuthContext()

    const handleOrganisation = (props) => {
        setActiveOrganisation(props)
        localStorage.setItem("activeOrganisation", props)
    }

    // Map function with all organisations user belongs to
    const organisations = userData.organisations

    return (

        <nav className="bg-white border-b border-gray-200 fixed z-30 w-full">
            <div className="px-3 py-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between">
                    <div className="flex items-center justify-start">
                        <button id="toggleSidebarMobile" aria-expanded="true" aria-controls="sidebar" className="lg:hidden mr-2 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded">
                            <svg id="toggleSidebarMobileHamburger" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                            </svg>
                            <svg id="toggleSidebarMobileClose" className="w-6 h-6 hidden" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </button>
                        <Link to="/" className="text-xl font-bold flex items-center lg:ml-2.5">
                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/proactive-logo.svg`} className="h-8 mr-2" alt="WP" />
                        </Link>

                        <div className="lg:block lg:pl-32">

                            <form className="organisation-select relative flex flex-row items-center inline-flex justify-center w-full leading-5">

                                <select
                                    name="organisation"
                                    className="appearance-none px-4 py-2 rounded-md shadow-sm text-sm font-medium text-gray-700 transition duration-150 ease-in-out bg-transparent border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                    onChange={e => handleOrganisation(e.target.value)}
                                    value={activeOrganisation}
                                >

                                    {organisations && (organisations.map((organisation, index) => (
                                        <option
                                            selected={activeOrganisation === organisation.id ? 'selected' : '1'}
                                            className=""
                                            key={index}
                                            value={organisation.id}
                                        >
                                            {organisation.name}
                                        </option>
                                    )))}

                                </select>

                                <svg className="absolute w-5 h-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>


                            </form>

                        </div>

                    </div>

                    <div className="relative flex flex-col items-center justify-center">
                        <div className="relative inline-block text-left dropdown">
                            <span className="rounded-md shadow-sm">
                                <button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                    type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items-117">
                                    <span>Options</span>
                                    <svg className="w-5 h-5 ml-2 -mr-1" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </span>
                            <div className="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95">
                                <div className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none" aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items-117" role="menu">
                                    <div className="px-4 py-3">
                                        <p className="text-sm leading-5">Signed in as</p>
                                        <p className="text-sm font-medium leading-5 text-gray-900 truncate">{userData.email}</p>
                                    </div>
                                    <div className="py-1">
                                        <Link to="/profile">
                                            <p className="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left" role="menuitem">Account settings</p>
                                        </Link>
                                        <Link to="/user-management">
                                            <p className="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left" role="menuitem">User management</p>
                                        </Link>
                                        <div className="py-1">
                                            <button onClick={logOut} tabIndex="3" className="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left" role="menuitem">Sign out</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </nav>

    )

}

export default Nav
