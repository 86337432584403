import Nav from '../Nav'
import Sidebar from '../Sidebar'

const Layout = ({ children }) => {

    return (

        <div className="App">

            <div>

                <Nav />

                <div className="flex overflow-hidden h-screen bg-white pt-16">

                    <Sidebar />

                    <div className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10" id="sidebarBackdrop"></div>
                    <div id="main-content" className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64">
                        <main>
                            <div className="pt-4 px-4">

                                {children}

                            </div>
                        </main>

                    </div>
                </div>

            </div>


        </div>

    )

}

export default Layout