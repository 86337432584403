import { useState, useEffect, useRef } from "react";
import axios from "../../api/AxiosHttp";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthProvider";
import Noty from "../../lib/Noty";
import Card from "../../components/Card";

const AddUser = ({
  onBoardingNotification,
  onBoardingCompleteNotification,
}) => {
  const { activeOrganisation } = useAuthContext();

  const [sites, setSites] = useState([]);

  const counter = useRef(0);
  const totalCounter = useRef(0);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");

  // const onBoardingNoty = new Noty({
  //   type: "info",
  //   text: "Onboarding is running in the background.",
  //   timeout: false,
  //   modal: true,
  // });

  useEffect(() => {
    if (!onBoardingNotification) return;
    var type = onBoardingNotification.error ? "error" : "success";
    var text = onBoardingNotification.error
      ? onBoardingNotification.error
      : "User was successfully added to " + onBoardingNotification.website.url;
    var onBoardingMessage = new Noty({
      type: type,
      text: text,
    }).show();

    counter.current = ++counter.current;

    new Noty({
      text:
        "Onboarding is running in the background. " +
        counter.current +
        "/" +
        totalCounter.current,
      type: "info",
      timeout: false,
      modal: true,
      queue: "onBoarding",
      killer: "onBoarding",
      force: true,
      animation: {
        open: null,
        close: null,
      },
    }).show();
  }, [onBoardingNotification]);

  useEffect(() => {
    if (!onBoardingCompleteNotification) return;
    setTimeout(() => {
      new Noty({
        text: onBoardingCompleteNotification.message,
        type: "success",
        timeout: 3000,
        modal: true,
        queue: "onBoarding",
        killer: "onBoarding",
        progressBar: true,
        force: true,
        animation: {
          open: null,
        },
      }).show();
      navigate("/users");
      onBoardingNotification = ''
      onBoardingCompleteNotification = ''

    }, 3000);
  }, [onBoardingCompleteNotification]);

  // socket.channel("OnBoarding").listen("OnboardingNotification", (e) => {
  //   console.log(e.data, "RESPONSEONBOARD");
  //   var type = e.data.error ? "error" : "success";
  //   var text = e.data.error
  //     ? e.data.error
  //     : "User was successfully added to " + e.data.website.url;
  //   var onBoardingMessage = new Noty({
  //     type: type,
  //     text: text,
  //   }).show();
  // });

  // socket.channel("OnBoarding").listen("OnboardingCompleteNotification", (e) => {
  //   console.log(e.data);
  //   if (e.data.message) {
  //     setTimeout(() => {
  //       onBoardingNoty.setTimeout(3000);
  //       onBoardingNoty.setType("success");
  //       onBoardingNoty.setText(e.data.message);
  //       navigate("/users");
  //     }, 3000);
  //   }
  // });

  useEffect(() => {
    getSites();
  }, [activeOrganisation]);

  const getSites = () => {
    if (activeOrganisation) {
      const endPoint = "/api/websites/org/" + activeOrganisation;
      axios.get(endPoint).then((result) => {
        setSites(result.data);
      });
    }
  };

  const addUserForm = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    let formObject = Object.fromEntries(formData.entries());
    formObject["organisation_id"] = activeOrganisation;
    //Set endpoint
    var endPoint =
      formObject["website_id"] == -1
        ? "/api/wpusers/onboarding"
        : "/api/wpusers";

    //TODO: modify success message if onboarding
    axios
      .post(endPoint, formObject)
      .then((result) => {
        console.log(result);
        console.log(formObject);
        if (formObject["website_id"] != -1) {
          setTimeout(() => {
            // onBoardingNoty.setTimeout(3000);
            // onBoardingNoty.setType("success");
            // onBoardingNoty.setText("User was successfully added");

            new Noty({
              text: "User was successfully added",
              type: "success",
              timeout: 3000,
              modal: true,
              queue: "onBoarding",
              killer: "onBoarding",
              progressBar: true,
              force: true,
              animation: {
                open: null,
              },
            }).show();

            navigate("/users");
          }, 3000);
        } else {
          totalCounter.current = result.data.counter;
          new Noty({
            text: result.data.message + " 1/" + result.data.counter,
            type: "info",
            timeout: false,
            modal: true,
            queue: "onBoarding",
            killer: "onBoarding",
            force: true,
            animation: {
              close: null,
            },
          }).show();
        }
      })
      .catch(function (error) {
        console.log(error);
        new Noty({
          type: "error",
          text: error.response.data.error,
        }).show();
      });
  };

  return (
    <Card>
      <div className="flex flex-col align-center w-1/2 whitespace-nowrap m-auto">
        <h3 className="text-xl mb-4 font-bold leading-none text-gray-900">
          Add User
        </h3>
        <p className="block text-md font-light mb-4">
          Use the form below to add a user to another website
        </p>
        <form onSubmit={addUserForm} className="space-y-6 text-sm mt-4">
          <div className="space-y-1 flex flex-col items-start">
            <label className="font-medium" htmlFor="username">
              Username
            </label>
            <input
              required
              name="username"
              id="username"
              className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              type="text"
            />
          </div>

          <div className="space-y-1 flex flex-col items-start">
            <label className="font-medium" htmlFor="email">
              Email
            </label>
            <input
              required
              name="email"
              id="email"
              className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              type="text"
              value={email}
            />
          </div>

          <div className="space-y-1 flex flex-col items-start">
            <label className="font-medium" htmlFor="firstname">
              First Name
            </label>
            <input
              required
              name="firstname"
              id="firstname"
              className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              type="text"
            />
          </div>

          <div className="space-y-1 flex flex-col items-start">
            <label className="font-medium" htmlFor="lastname">
              Last Name
            </label>
            <input
              required
              name="lastname"
              id="lastname"
              className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              type="text"
            />
          </div>

          <div className="space-y-1 flex flex-col items-start">
            <label className="font-medium" htmlFor="password">
              Password
            </label>
            <input
              required
              name="password"
              id="password"
              className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              type="password"
            />
          </div>

          <div className="space-y-1 flex flex-col items-start">
            <label className="font-medium" htmlFor="roles">
              Role
            </label>
            <div className="relative  w-full">
              <select
                name="roles"
                id="roles"
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option selected="selected" value="administrator">
                  Administrator
                </option>
                <option value="editor">Editor</option>
                <option value="author">Author</option>
                <option value="contributor">Contributor</option>
                <option value="subscriber">Subscriber</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <hr />
          <div className="space-y-1 flex flex-col items-start">
            <label className="font-medium" htmlFor="website_id">
              Sites
            </label>
            <div className="relative  w-full">
              <select
                name="website_id"
                id="website_id"
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
              >
                <option value="-1">All Sites</option>
                {
                  //TODO: Remove All sites if none are found
                  sites.length > 0 &&
                    sites.map((site, index) => (
                      <option key={index} value={site.id}>
                        {site.name}
                      </option>
                    ))
                }
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="space-y-1 flex flex-col items-start">
            <button
              type="submit"
              className="hidden sm:inline-flex text-center m-auto text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 content-start text-center items-left mr-3"
            >
              Add User
            </button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default AddUser;
