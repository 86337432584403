import Noty from 'noty';
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/metroui.css";
import "../noty.css";

export default Noty.overrideDefaults({
    layout: 'topRight',
    type: 'information',
    theme: 'metroui',
    closeWith: ['click'],
    timeout: 3000,
    progressBar: false
});