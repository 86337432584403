export const isValidHttpUrl = (string) => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "https:";
}

export const findParentIndex = (id, rows) => {
    return rows.findIndex(row => row.id == id);
}