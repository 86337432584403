import { useState } from "react";
import axios from "../../api/AxiosHttp";
import Paper from "@mui/material/Paper";
import { RowDetailState } from "@devexpress/dx-react-grid";
import {
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import Noty from "../../lib/Noty";
import { findParentIndex } from "../../lib/Helper";

const DataTable = (props) => {
  const [rows, setRows] = useState(props.sites);

  const getRowId = (row) => row.id;
  const columns = [
    { name: "name", title: "Name" },
    { name: "url", title: "URL" },
    { name: "username", title: "App Password User" },
    { name: "last_synced_at", title: "Last Synced" },
    { name: "status", title: "Status" },
  ];

  //TODO: Disable button
  const syncSite = async (e) => {
    e.target.disabled = true;

    const websiteId = e.target.getAttribute("data-website-id");
    const websiteName = e.target.getAttribute("data-website-name");
    const endPoint = "/api/websites/sync";
    const organisationId = e.target.getAttribute("data-organisation-id");

    const syncNoty = new Noty({
      type: "info",
      text: websiteName + " is syncing",
      timeout: false,
    }).show();

    axios
      .post(endPoint, {
        website_id: websiteId,
        organisation_id: organisationId,
      })
      .then((result) => {
        console.log(result);
        let indexForAdding = findParentIndex(websiteId, rows);

        const newRows = [];

        rows.forEach(modifyRows);

        function modifyRows(item, index, arr) {
          if (index == indexForAdding) {
            newRows[index] = result.data;
          } else {
            newRows[index] = arr[index];
          }
        }

        setRows(newRows);

        setTimeout(() => {
          syncNoty.setText(websiteName + " has been synced successfully");
          syncNoty.setType("success");
          syncNoty.setTimeout(3000);
        }, 2000);
      })
      .catch(function (error) {
        console.log(error.response);
        syncNoty.setText(error.response.data.error);
        syncNoty.setType("error");
        syncNoty.setTimeout(3000);

        if (error.response.data.error_type == "api") {
          let indexForAdding = findParentIndex(websiteId, rows);

          const newRows = [];

          rows.forEach(modifyRows);

          function modifyRows(item, index, arr) {
            if (index == indexForAdding) {
              newRows[index] = error.response.data.website;
            } else {
              newRows[index] = arr[index];
            }
          }

          setRows(newRows);
        }
      });
  };

  const handleRemoveSite = async (e) => {
    //TODO: disable button to prevent duplicate clicks
    const websiteId = e.target.getAttribute("data-website-id");
    const websiteName = e.target.getAttribute("data-website-name");
    const endPoint = "/api/websites/delete";
    const organisationId = e.target.getAttribute("data-organisation-id");

    var n = new Noty({
      type: "error",
      text:
        "Are you sure you want to remove " +
        websiteName +
        "? The site won't be affected.",
      layout: "center",
      modal: "true",
      animation: {
        open: "null",
      },
      buttons: [
        Noty.button(
          "YES",
          "btn btn-success",
          function () {
            n.close();
            axios
              .post(endPoint, {
                website_id: websiteId,
                organisation_id: organisationId,
              })
              .then((result) => {
                let indexForRemoval = findParentIndex(websiteId, rows);
                let valueToRemove = [rows[indexForRemoval]];

                setRows(
                  rows.filter((element) => !valueToRemove.includes(element))
                );

                new Noty({
                  type: "success",
                  text: websiteName + " has been removed",
                }).show();
              })
              .catch((error) => {
                new Noty({
                  type: "error",
                  text: error.response.data.error,
                }).show();
              });
          },
          { id: "button1", "data-status": "ok" }
        ),

        Noty.button("NO", "btn btn-error", function () {
          n.close();
        }),
      ],
    });
    n.show();
  };

  const handleRemoveUser = (e) => {
    const email = e.target.getAttribute("data-user-email");
    const websiteId = e.target.getAttribute("data-website-id");
    const organisationId = e.target.getAttribute("data-organisation-id");
    const userName = e.target.getAttribute("data-user-name");
    const endPoint = "/api/wpusers/delete";
    const appPassUserName = e.target.getAttribute("data-app-pass");
    var text = "Do you want to delete " + email + " ?";

    if (userName == appPassUserName) {
      var text =
        "The site is connected using " +
        userName +
        "'app password. If you remove this user the site will be inactive.";
    }

    var n = new Noty({
      type: "error",
      text: text,
      layout: "center",
      modal: "true",
      animation: {
        open: "null",
      },
      buttons: [
        Noty.button(
          "YES",
          "btn btn-success",
          function () {
            n.close();
            axios
              .post(endPoint, {
                username: userName,
                email: email,
                website_id: websiteId,
                organisation_id: organisationId,
                type: "website",
              })
              .then((result) => {
                let indexForAdding = findParentIndex(websiteId, rows);
                const newRows = [];
                rows.forEach(modifyRows);

                function modifyRows(item, index, arr) {
                  if (index == indexForAdding) {
                    newRows[index] = result.data.website;
                  } else {
                    newRows[index] = arr[index];
                  }
                }

                setRows(newRows);

                new Noty({
                  type: "success",
                  text:
                    email +
                    " was successfully deleted and it's content got assigned to " +
                    result.data.admin_email,
                  timeout: 5000,
                }).show();
              })
              //TODO: check if text is showing error
              .catch((error) => {
                new Noty({
                  type: "error",
                  text: error.response.data.error,
                }).show();
              });
          },
          { id: "button1", "data-status": "ok" }
        ),

        Noty.button("NO", "btn btn-error", function () {
          n.close();
        }),
      ],
    });
    n.show();
  };

  const connectSite = (e) => {
    const organisationId = e.target.getAttribute("data-organisation-id");
    const url = e.target.getAttribute("data-url");
    const name = e.target.getAttribute("data-website-name");

    axios
      .post("/api/websites/auth", {
        url: url,
        name: name,
        organisation_id: organisationId,
      })
      .then((result) => {
        new Noty({
          type: "success",
          text: "You will be redirected...",
        }).show();

        setTimeout(() => {
          window.location.replace(result.data);
          return null;
        }, 2000);
      })
      .catch(function (error) {
        console.log(error.response);

        new Noty({
          type: "error",
          text: error.response.data.error,
          timeout: 3000,
        }).show();
      });
  };

  const HighlightedCell = ({ value, style, ...restProps }) => (
    <Table.Cell>
      <span
        className={
          "inline-flex px-2 text-xs font-semibold leading-5 rounded-full " +
          (value == "active"
            ? "text-green-800 bg-green-100"
            : "text-red-800 bg-red-100")
        }
      >
        {value}
      </span>
    </Table.Cell>
  );

  const Cell = (props) => {
    const { column } = props;
    if (column.name === "status") {
      return <HighlightedCell {...props} />;
    }
    return <Table.Cell {...props} />;
  };

  const RowDetail = ({ row }) => (
    <div className="min-w-full text-sm align-middle whitespace-nowrap">
      <button
        onClick={handleRemoveSite}
        className="mb-6 hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2 text-center items-center mr-3"
        type="button"
        data-website-name={row.name}
        data-organisation-id={row.organisation_id}
        data-website-id={row.id}
      >
        Remove Site
      </button>
      {row.status == "inactive" ? (
        <button
          onClick={connectSite}
          className="float-left hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2 text-center items-center mr-3"
          type="button"
          data-website-name={row.name}
          data-organisation-id={row.organisation_id}
          data-website-id={row.id}
          data-url={row.url}
        >
          Connect site
        </button>
      ) : (
        <>
          <button
            onClick={syncSite}
            className="float-left hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2 text-center items-center mr-3"
            type="button"
            data-website-name={row.name}
            data-organisation-id={row.organisation_id}
            data-website-id={row.id}
          >
            Sync Site
          </button>

          <table className="min-w-full text-sm align-middle whitespace-nowrap">
            <thead>
              <tr className="border-b border-gray-200">
                <th className="p-3 text-gray-700 bg-gray-100 font-semibold text-sm tracking-wider text-center">
                  Name
                </th>
                <th className="p-3 text-gray-700 bg-gray-100 font-semibold text-sm tracking-wider text-center">
                  Username
                </th>
                <th className="p-3 text-gray-700 bg-gray-100 font-semibold text-sm tracking-wider text-center">
                  Email
                </th>
                <th className="p-3 text-gray-700 bg-gray-100 font-semibold text-sm tracking-wider text-center">
                  Role
                </th>
                <th className="p-3 text-gray-700 bg-gray-100 font-semibold text-sm tracking-wider text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {row.users.map((user, index) => (
                <tr className="border-b border-gray-200" key={index}>
                  <td className="p-3 text-gray-500 text-center">{user.name}</td>
                  <td className="p-3 text-gray-500 text-center">
                    {user.username}
                  </td>
                  <td className="p-3 text-gray-500 text-center">
                    {user.email}
                  </td>
                  <td className="p-3 text-gray-500 text-center">{user.role}</td>
                  <td className="p-3 text-red-500 text-center">
                    <a
                      href="#"
                      data-app-pass={row.username}
                      data-user-name={user.username}
                      data-organisation-id={row.organisation_id}
                      data-website-id={row.id}
                      data-user-email={user.email}
                      onClick={handleRemoveUser}
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );

  return (
    <Paper>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState
          defaultSorting={[{ columnName: "name", direction: "asc" }]}
        />
        <IntegratedSorting />
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <RowDetailState />
        <PagingState defaultCurrentPage={0} pageSize={20} />
        <IntegratedPaging />
        <Table cellComponent={Cell} />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel />
        <TableRowDetail contentComponent={RowDetail} />
        <PagingPanel />
      </Grid>
    </Paper>
  );
};

export default DataTable;
