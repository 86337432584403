import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../api/AxiosHttp";
import Cookies from "js-cookie";
import Noty from "../lib/Noty";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState();
  const [userData, setUserData] = useState([]);
  const [activeOrganisation, setActiveOrganisation] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (window.location.pathname !== "/forgot-password") {
      if (!window.location.pathname.match("reset-password")) {
        getUserData();
      }
    }
  }, []);

  const getUserData = async () => {
    console.log("Init getUserData()");

    const userCookie = Cookies.get("user_management_is_user_logged_in");

    if (userCookie) {
      await axios
        .get("/api/me")
        .then((result) => {
          setAuth(userCookie);
          setUserData(result.data);

          //TODO: clean up if possible
          if (result.data.organisations.length > 0) {
            if (localStorage.getItem("activeOrganisation")) {
              setActiveOrganisation(
                JSON.parse(localStorage.getItem("activeOrganisation"))
              );
            } else {
              setActiveOrganisation(result.data.organisations[0].id);
            }
          } else {
            logOut();
            new Noty({
              type: "error",
              text: "No organisations assigned",
            }).show();
          }

          console.log(result, "getUserData Success");
        })
        .catch(function (error) {
          setAuth("");
          setUserData("");
          setActiveOrganisation("");
          Cookies.remove("user_management_is_user_logged_in");

          console.log("getUserData Error");

          if (location.pathname !== "/login") {
            navigate("/login");
          }
        });
    } else if (location.pathname !== "/login") {
      navigate("/login");
    }
  };

  const logOut = async () => {
    axios.post("/api/logout").then();
    setAuth("");
    setUserData("");
    Cookies.remove("user_management_is_user_logged_in", {
      expires: 86400,
      sameSite: "lax",
    });
    navigate("/login");

    console.log("logOut Success");
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        userData,
        setUserData,
        getUserData,
        activeOrganisation,
        setActiveOrganisation,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthContext() {
  return useContext(AuthContext);
}
