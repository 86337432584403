import { useEffect, useState, useRef } from "react";
import axios from "../../api/AxiosHttp";
import { useAuthContext } from "../../context/AuthProvider";
import Noty from "../../lib/Noty";
import Popup from "reactjs-popup";
import "../../modal.css";
import "reactjs-popup/dist/index.css";

const Profile = () => {
  const { userData, setUserData } = useAuthContext();
  const [QRSvg, setQRSvg] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [twoFactor, setTwoFactor] = useState();

  const closeModal = () => setOpenModal(false);

  const confirm2FACode = useRef(null);

  useEffect(() => {
    setTwoFactor(!!userData.two_factor_recovery_codes);
  }, [userData]);

  console.log(userData);
  console.log(twoFactor);

  const updateProfile = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let formObject = Object.fromEntries(formData.entries());

    const endPoint = "api/user/profile-information";
    axios
      .put(endPoint, formObject)
      .then((result) => {
        let newUserData = userData;
        newUserData.name = formObject.name;
        newUserData.email = formObject.email;
        setUserData(newUserData);

        new Noty({
          type: "success",
          text: "Profile was updated successfully",
          timeout: 3000,
          modal: true,
          progressBar: true,
        }).show();
      })
      .catch(function (error) {
        if (error.response) {
          var er = error.response.data.errors;
          var notymessage = er[Object.keys(er)[0]];

          new Noty({
            type: "error",
            text: notymessage,
          }).show();
        }
      });
  };

  const confirm2FA = () => {
    const endPoint = "api/user/confirmed-two-factor-authentication";

    const confirm2FAinput = confirm2FACode.current.value;

    console.log(confirm2FAinput, "confirm2FAinput");

    axios
      .post(endPoint, { code: confirm2FAinput })
      .then((result) => {
        console.log(result);
        closeModal();
        setTwoFactor(true);

        new Noty({
          type: "success",
          text: "Two Factor was successfully added.",
          timeout: 3000,
          modal: true,
          progressBar: true,
        }).show();

      })
      .catch(function (error) {
        if (error.response) {
          var er = error.response.data.errors;
          var notymessage = er[Object.keys(er)[0]];

          new Noty({
            type: "error",
            text: notymessage,
          }).show();
        }
      });
  };

  const getQRCode = () => {
    const endPoint = "api/user/two-factor-qr-code";
    axios
      .get(endPoint)
      .then((result) => {
        console.log(result);
        setQRSvg(result.data.svg);
        setOpenModal(true);
      })
      .catch(function (error) {
        if (error.response) {
          var er = error.response.data.errors;
          var notymessage = er[Object.keys(er)[0]];

          new Noty({
            type: "error",
            text: notymessage,
          }).show();
        }
      });
  };

  const enable2FA = () => {
    const endPoint = "api/user/two-factor-authentication";
    axios
      .post(endPoint)
      .then((result) => {
        console.log(result);
        getQRCode();
      })
      .catch(function (error) {
        if (error.response) {
          var er = error.response.data.errors;
          var notymessage = er[Object.keys(er)[0]];

          new Noty({
            type: "error",
            text: notymessage,
          }).show();
        }
      });
  };

  const disable2FA = () => {
    const endPoint = "api/user/two-factor-authentication";
    axios
      .delete(endPoint)
      .then((result) => {
        console.log(result);
        setTwoFactor(false);

        new Noty({
          type: "success",
          text: "Two Factor was removed, we recommend setting this.",
          timeout: 3000,
          modal: true,
          progressBar: true,
        }).show();
      })
      .catch(function (error, status) {
        console.log(error);
        console.log(status);
        if (error.response) {
          new Noty({
            type: "error",
            text: error.response.data.message,
          }).show();
        }
      });
  };

  const toggleTwoAuth = (e) => {
    e.preventDefault();

    console.log(e);

    const formData = new FormData(e.currentTarget);
    let formObject = Object.fromEntries(formData.entries());
    console.log(formObject);
    e.target.reset();

    const actionType = formObject.action;

    const endPoint = "api/user/confirm-password";
    axios
      .post(endPoint, { password: formObject.password })
      .then((result) => {
        console.log(result);
        actionType == "enable" ? enable2FA() : disable2FA();
      })
      .catch(function (error) {
        if (error.response) {
          var er = error.response.data.errors;
          var notymessage = er[Object.keys(er)[0]];

          new Noty({
            type: "error",
            text: notymessage,
          }).show();
        }
      });
  };

  const updatePassword = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let formObject = Object.fromEntries(formData.entries());
    console.log(formObject);
    const endPoint = "api/user/password";
    axios
      .put(endPoint, formObject)
      .then((result) => {
        console.log(result);
        e.target.reset();
        new Noty({
          type: "success",
          text: "Password was successfully updated",
          timeout: 3000,
          modal: true,
          progressBar: true,
        }).show();
      })
      .catch(function (error) {
        if (error.response) {
          var er = error.response.data.errors;
          var notymessage = er[Object.keys(er)[0]];

          new Noty({
            type: "error",
            text: notymessage,
          }).show();
        }
      });
  };

  return (
    <>
      <Popup
        open={openModal}
        onClose={closeModal}
        closeOnEscape={false}
        closeOnDocumentClick={false}
        modal
      >
          <div className="modal">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: QRSvg }}
            ></div>
            <div className="input">
              <input
                ref={confirm2FACode}
                type="number"
                className="w-full px-4 py-3 rounded-lg mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
              ></input>
            </div>
            <div className="actions">
              <button
                className="button"
                onClick={() => {
                  console.log("modal closed ");
                  // close();
                  confirm2FA();
                }}
              >
                Confirm your 2FA code
              </button>
            </div>
          </div>
      </Popup>
      <div className="space-y-4 lg:space-y-4">
        <div className="flex flex-col rounded shadow-sm bg-white overflow-hidden">
          <div className="p-5 lg:p-6 grow w-full">
            <div className="md:flex">
              <div className="text-left md:flex-none md:w-1/3 border-b md:border-0 mb-5 md:mb-0">
                <h3 className="flex items-center space-x-2 font-semibold mb-2">
                  <svg
                    className="hi-solid hi-user-circle inline-block w-5 h-5 text-indigo-500"
                    fill="#0792b1"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span>User Profile</span>
                </h3>
                <p className="text-gray-500 text-sm mb-5">
                  Your account’s vital info. Only your username and photo will
                  be publicly visible.
                </p>
              </div>
              <div className="md:w-2/3 md:pl-24">
                <form
                  className="space-y-6"
                  encType="multipart/form-data"
                  onSubmit={updateProfile}
                >
                  <div className="space-y-1 flex flex-col items-start">
                    <label className="font-medium" htmlFor="name">
                      Name
                    </label>
                    <input
                      className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      type="text"
                      id="name"
                      name="name"
                      defaultValue={userData.name}
                      // onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="space-y-1 flex flex-col items-start">
                    <label className="font-medium" htmlFor="email">
                      Email
                    </label>
                    <input
                      className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      type="email"
                      id="email"
                      name="email"
                      defaultValue={userData.email}
                    />
                  </div>
                  <button
                    type="submit"
                    className="float-left hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center mr-3"
                  >
                    Update Profile
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col rounded shadow-sm bg-white overflow-hidden">
          <div className="p-4 lg:p-6 grow w-full">
            <div className="md:flex">
              <div className="text-left md:flex-none md:w-1/3 border-b md:border-0 mb-5 md:mb-0">
                <h3 className="flex items-center space-x-2 font-semibold mb-2">
                  <svg
                    className="hi-solid hi-lock-open inline-block w-5 h-5 text-indigo-500"
                    fill="#0792b1"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"></path>
                  </svg>
                  <span>Change Password</span>
                </h3>
                <p className="text-gray-500 text-sm mb-5">
                  Changing your sign in password is an easy way to keep your
                  account secure.
                </p>
              </div>
              <div className="md:w-2/3 md:pl-24">
                <form className="space-y-6" onSubmit={updatePassword}>
                  <div className="space-y-1 flex flex-col items-start">
                    <label className="font-medium" htmlFor="password">
                      Current Password
                    </label>
                    <input
                      className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      type="password"
                      id="current_password"
                      name="current_password"
                    />
                  </div>
                  <div className="space-y-1 flex flex-col items-start">
                    <label className="font-medium" htmlFor="password_new">
                      New Password
                    </label>
                    <input
                      className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      type="password"
                      id="password"
                      name="password"
                    />
                  </div>
                  <div className="space-y-1 flex flex-col items-start">
                    <label
                      className="font-medium"
                      htmlFor="password_new_confirm"
                    >
                      Confirm Password
                    </label>
                    <input
                      className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      type="password"
                      id="password_confirm"
                      name="password_confirmation"
                    />
                  </div>
                  <button
                    type="submit"
                    className="float-left hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center mr-3"
                  >
                    Update Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col rounded shadow-sm bg-white overflow-hidden">
          <div className="p-4 lg:p-6 grow w-full">
            <div className="md:flex">
              <div className="text-left md:flex-none md:w-1/3 border-b md:border-0 mb-5 md:mb-0">
                <h3 className="flex items-center space-x-2 font-semibold mb-2">
                  <svg
                    className="hi-solid hi-lock-open inline-block w-5 h-5 text-indigo-500"
                    fill="#0792b1"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"></path>
                  </svg>
                  <span>Two-Factor Authentication</span>
                </h3>
                <p className="text-gray-500 text-sm mb-5">
                  Set up Two Factor Authentication for extra protection.
                </p>
                <p className="text-gray-500 text-sm mb-5">
                  Two Factor Authentication is currently
                  {twoFactor ? (
                    <span className="text-green-600"> enabled</span>
                  ) : (
                    <span className="text-red-600"> disabled</span>
                  )}
                </p>
              </div>
              <div className="md:w-2/3 md:pl-24">
                {!twoFactor ? (
                  <form className="space-y-6" onSubmit={toggleTwoAuth}>
                    <input type="hidden" name="action" value="enable"></input>
                    <div className="space-y-1 flex flex-col items-start">
                      <label className="font-medium" htmlFor="password">
                        Current Password
                      </label>
                      <input
                        className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                        type="password"
                        id="password"
                        name="password"
                      />
                    </div>
                    <button
                      type="submit"
                      className="float-left hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center mr-3"
                    >
                      Enable 2FA
                    </button>
                  </form>
                ) : (
                  <form className="space-y-6" onSubmit={toggleTwoAuth}>
                    <input type="hidden" name="action" value="disable"></input>
                    <div className="space-y-1 flex flex-col items-start">
                      <label className="font-medium" htmlFor="password">
                        Current Password
                      </label>
                      <input
                        className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                        type="password"
                        id="password"
                        name="password"
                      />
                    </div>
                    <button
                      type="submit"
                      className="float-left hidden sm:inline-flex text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center mr-3"
                    >
                      Disable 2FA
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
