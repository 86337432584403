import React, { useState } from "react";
import axios from "../../api/AxiosHttp";
import Paper from "@mui/material/Paper";
import { RowDetailState } from "@devexpress/dx-react-grid";
import {
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import Noty from "../../lib/Noty";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthProvider";


const DataTable = (props) => {
  const [rows, setRows] = useState(props.users);
  const navigate = useNavigate();
  const getRowId = (row) => row.id;
  const columns = [{ name: "email", title: "Email" }];

  const { activeOrganisation } = useAuthContext();

  const handleOffBoarding = (e) => {
    const email = e.target.getAttribute("data-user-email");
    const endPoint = "/api/wpusers/offboarding";
    var text = "Do you want to delete " + email + " ?";
    console.log(activeOrganisation);

    var n = new Noty({
      type: "error",
      text: text,
      layout: "center",
      modal: "true",
      animation: {
        open: "null",
      },
      buttons: [
        Noty.button(
          "YES",
          "btn btn-success",
          function () {
            n.close();
            axios
            .post(endPoint, { email: email, organisation_id: activeOrganisation })
            .then((result) => {
      
              console.log(result);
      
              new Noty({
                text:
                  "Offboarding is running in the background. 1/" + result.data.counter,
                type: "info",
                timeout: false,
                modal: true,
                queue: "offBoarding",
                killer: "offBoarding",
                force: true,
                animation: {
                  close: null,
                },
              }).show();
            });
          },
          { id: "button1", "data-status": "ok" }
        ),

        Noty.button("NO", "btn btn-error", function () {
          n.close();
        }),
      ],
    });
    n.show();

  };

  const handleAddUser = (e) => {
    const email = e.target.getAttribute("data-user-email");
    console.log(email);
    navigate("/user/add/?email=" + email);
  };

  const handleRemoveUser = (e) => {
    const email = e.target.getAttribute("data-user-email");
    const websiteId = e.target.getAttribute("data-website-id");
    const organisationId = e.target.getAttribute("data-organisation-id");
    const userName = e.target.getAttribute("data-user-name");
    const endPoint = "/api/wpusers/delete";
    const appPassUserName = e.target.getAttribute("data-app-pass");
    var text = "Do you want to delete " + email + " ?";

    if (userName == appPassUserName) {
      var text =
        "The site is connected using " +
        userName +
        "'app password. If you remove this user the site will be inactive.";
    }

    var n = new Noty({
      type: "error",
      text: text,
      layout: "center",
      modal: "true",
      animation: {
        open: "null",
      },
      buttons: [
        Noty.button(
          "YES",
          "btn btn-success",
          function () {
            n.close();
            axios
              .post(endPoint, {
                username: userName,
                email: email,
                website_id: websiteId,
                organisation_id: organisationId,
                type: "user",
              })
              .then((result) => {
                setRows(result.data);

                new Noty({
                  text: "User was deleted sucessfully.",
                  type: "success",
                  timeout: 3000,
                  modal: true,
                  progressBar: true
                }).show();

                console.log(result, "SHOW USERS");
              })
              .catch((error) => {
                console.log(error);
                new Noty({
                  type: "error",
                  text: error.result.data.error,
                }).show();
              });
          },
          { id: "button1", "data-status": "ok" }
        ),

        Noty.button("NO", "btn btn-error", function () {
          n.close();
        }),
      ],
    });
    n.show();
  };

  const RowDetail = ({ row }) => (
    <div className="min-w-full text-sm align-middle whitespace-nowrap">
      <button
        onClick={handleOffBoarding}
        className="mb-6 hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2 text-center items-center mr-3"
        type="button"
        data-user-email={row.email}
      >
        Offboard User
      </button>
      <button
        onClick={handleAddUser}
        className="mb-6 hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2 text-center items-center mr-3"
        type="button"
        data-user-email={row.email}
      >
        Add User To Another Site
      </button>
      <div></div>
      <table className="min-w-full text-sm align-middle whitespace-nowrap">
        <thead>
          <tr className="border-b border-gray-200">
            <th className="p-3 text-gray-700 bg-gray-100 font-semibold text-sm tracking-wider text-center">
              Name
            </th>
            <th className="p-3 text-gray-700 bg-gray-100 font-semibold text-sm tracking-wider text-center">
              URL
            </th>
            <th className="p-3 text-gray-700 bg-gray-100 font-semibold text-sm tracking-wider text-center">
              Username
            </th>
            <th className="p-3 text-gray-700 bg-gray-100 font-semibold text-sm tracking-wider text-center">
              Role
            </th>
            <th className="p-3 text-gray-700 bg-gray-100 font-semibold text-sm tracking-wider text-center">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {row.websites.map((website, index) => (
            <tr className="border-b border-gray-200" key={index}>
              <td className="p-3 text-gray-500 text-center">
                {
                  row.names.find((name) => name.website_id == website[0].id)
                    .name
                }
              </td>
              <td className="p-3 text-gray-500 text-center">
                {website[0].url}
              </td>
              <td className="p-3 text-gray-500 text-center">
                {
                  row.usernames.find(
                    (username) => username.website_id == website[0].id
                  ).username
                }
              </td>
              <td className="p-3 text-gray-500 text-center">
                {
                  row.roles.find((role) => role.website_id == website[0].id)
                    .role
                }
              </td>
              {/* <td className="p-3 text-gray-500 text-center"><Link to={"/user/" + row.userids.find(userid => userid.website_id == website[0].id).userid}>Edit</Link></td> */}
              <td className="p-3 text-red-500 text-center">
                <a
                  href="#"
                  data-user-email={row.email}
                  data-website-id={website[0].id}
                  data-organisation-id={website[0].organisation_id}
                  data-user-name={
                    row.usernames.find(
                      (username) => username.website_id == website[0].id
                    ).username
                  }
                  data-app-pass={website[0].username}
                  data-user-id={
                    row.userids.find(
                      (userid) => userid.website_id == website[0].id
                    ).userid
                  }
                  onClick={handleRemoveUser}
                >
                  Delete
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <Paper>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState
          defaultSorting={[{ columnName: "email", direction: "asc" }]}
        />
        <IntegratedSorting />
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <RowDetailState />
        <PagingState defaultCurrentPage={0} pageSize={20} />
        <IntegratedPaging />
        <Table />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel />
        <TableRowDetail contentComponent={RowDetail} />
        <PagingPanel />
      </Grid>
    </Paper>
  );
};

export default DataTable;
