import { useState, useEffect } from "react";
import axios from "../../api/AxiosHttp";
import AccountTable from "../../components/Table/Accounts";
import Noty from "../../lib/Noty";

const UserManagement = () => {
  const [accounts, setAccounts] = useState([]);
  const [updatedKey, setUpdatedKey] = useState(Date.now());

  useEffect(() => {
    getAccounts();
  }, []);

  const createUser = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let formObject = Object.fromEntries(formData.entries());
    console.log(formObject);
    const endPoint = "api/user";
    axios
      .post(endPoint, formObject)
      .then((result) => {
        console.log(result);
        e.target.reset();
        setAccounts(current => [...current, result.data]);
        setUpdatedKey(Date.now())
        console.log(accounts)
        new Noty({
          type: "success",
          text: "User was successfully added",
          timeout: 3000,
          modal: true,
          progressBar: true,
        }).show();
      })
      .catch(function (error) {
        console.log(error)
        if (error.response) {
          var er = error.response.data.error;
          var notymessage = er[Object.keys(er)[0]];
  
          new Noty({
            type: "error",
            text: notymessage,
          }).show();
        }
      });
  };

  const getAccounts = async () => {
    const endPoint = "/api/users";
    await axios.get(endPoint).then((result) => {
      setAccounts(result.data);
    });
  };

  //TODO: Delete User + Add user

  return (
    <div className="4">
      <div className="flex flex-col rounded shadow-sm bg-white overflow-hidden">
        <div className="p-5 lg:p-6 grow w-full">
          <div className="md:flex">
            <div className="text-left md:flex-none md:w-1/3 border-b md:border-0 mb-5 md:mb-0">
              <h3 className="flex items-center space-x-2 font-semibold mb-2">
                <svg
                  className="hi-solid hi-user-circle inline-block w-5 h-5 text-indigo-500"
                  fill="#0792b1"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Create User</span>
              </h3>
              <p className="text-gray-500 text-sm mb-5">
                Add users to manage your organisations.
              </p>
            </div>
            <div className="md:w-2/3 md:pl-24">
              <form
                className="space-y-6"
                encType="multipart/form-data"
                onSubmit={createUser}
              >
                <div className="space-y-1 flex flex-col items-start">
                  <label className="font-medium" htmlFor="name">
                    Name
                  </label>
                  <input
                    className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                    type="text"
                    id="name"
                    name="name"
                    required
                  />
                </div>
                <div className="space-y-1 flex flex-col items-start">
                  <label className="font-medium" htmlFor="email">
                    Email
                  </label>
                  <input
                    className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                    type="email"
                    id="email"
                    name="email"
                    required
                  />
                </div>
                <div className="space-y-1 flex flex-col items-start">
                  <label className="font-medium" htmlFor="email">
                    Password
                  </label>
                  <input
                    className="block border placeholder-gray-400 px-3 py-2 leading-6 w-full rounded border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                    type="password"
                    id="password"
                    name="password"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="float-left hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center mr-3"
                >
                  Add User
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="border border-gray-200 rounded overflow-x-auto min-w-full bg-white">
        {accounts.length > 0 && <AccountTable key={updatedKey} accounts={accounts} />}
      </div>
    </div>
  );
};

export default UserManagement;
