import { useState } from "react";
import { isValidHttpUrl } from "../../lib/Helper";
import { useAuthContext } from "../../context/AuthProvider";
import Card from "../../components/Card";
import Noty from "../../lib/Noty";
import axios from "../../api/AxiosHttp";

const AddWebsite = () => {
  const { activeOrganisation } = useAuthContext();
  
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");

  const addNewSite = () => {
    if (name) {
      if (isValidHttpUrl(url)) {
        const newSiteNoty = new Noty({
          type: "info",
          text: "Adding site",
          timeout: false,
          modal: true,
          closeWith: [],
        }).show();

        axios
          .post("/api/websites/auth", {
            url,
            name,
            organisation_id: activeOrganisation,
          })
          .then((result) => {
            newSiteNoty.setType("success");
            newSiteNoty.setText("You will be redirected...");
            newSiteNoty.setTimeout(2000);

            setTimeout(() => {
              window.location.replace(result.data);
              return null;
            }, 2000);
          })
          .catch(function (error) {
            newSiteNoty.setType("error");
            newSiteNoty.setText(error.response.data.error);
            newSiteNoty.setTimeout(2000);
          });
      } else {
        new Noty({
          type: "error",
          text: "Please add the website url with https",
          modal: true,
        }).show();
      }
    } else {
      new Noty({
        type: "error",
        text: "Please add a website name",
        modal: true,
      }).show();
    }
  };

  return (
    <Card>
      <h3 className="text-xl font-bold leading-none text-gray-900">
        Add new site
      </h3>
      <form className="mt-4 flex flex-col justify-center items-center">
        <div className="mb-4 max-w-2xl w-full">
          <label className="block text-md font-light mb-4" htmlFor="url">
            Add the name of the site and domain name below
          </label>
          <input
            onChange={(e) => setName(e.target.value)}
            value={name}
            className="mb-4 mb3 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-5 p-2.5"
            type="text"
            name="name"
            id=""
            required
            placeholder="Example Site"
          />
          <input
            onChange={(e) => setUrl(e.target.value)}
            value={url}
            className="mb3 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-5 p-2.5"
            type="url"
            name="url"
            id=""
            required
            placeholder="https://example.com"
          />
        </div>
        <button
          onClick={addNewSite}
          className="hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center mr-3"
          type="button"
        >
          Add Site
        </button>
      </form>
    </Card>
  );
};

export default AddWebsite;
