import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './context/AuthProvider'
import SocketProvider from './context/SocketProvider'
import './index.css'
import App from './App'

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <App socket={SocketProvider} />
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);