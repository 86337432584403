import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import Layout from "./components/Layout";
import Websites from "./pages/Websites";
import Team from "./pages/Team";
import Users from "./pages/Users";
import UserEdit from "./pages/Users/UserEdit";
import AddUser from "./pages/Users/AddUser";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import NotFound from "./pages/NotFound";
import AddSite from "./pages/Integration/AddSite";
import UserManagement from "./pages/UserManagement";
import "./App.css";

function App({ socket }) {
  const [syncAllNotification, setSyncAllNotification] = useState(null);
  const [syncAllCompleteNotification, setSyncAllCompleteNotification] =
    useState(null);

  const [onBoardingNotification, setOnBoardingNotification] = useState(null);
  const [onBoardingCompleteNotification, setOnboardingCompleteNotification] =
    useState(null);

  const [offBoardingNotification, setOffboardingNotification] = useState(null);
  const [offBoardingCompleteNotification, setOffboardingCompleteNotification] =
    useState(null);

  useEffect(() => {
    if (syncAllNotification !== null) {
      setSyncAllNotification(null);
    }
  }, [syncAllNotification]);

  useEffect(() => {
    if (syncAllCompleteNotification !== null) {
      setSyncAllCompleteNotification(null);
    }
  }, [syncAllCompleteNotification]);

  useEffect(() => {
    if (onBoardingNotification !== null) {
      setOnBoardingNotification(null);
    }
  }, [onBoardingNotification]);

  useEffect(() => {
    if (onBoardingCompleteNotification !== null) {
      setOnboardingCompleteNotification(null);
    }
  }, [onBoardingCompleteNotification]);

  useEffect(() => {
    if (offBoardingNotification !== null) {
      setOffboardingNotification(null);
    }
  }, [offBoardingNotification]);

  useEffect(() => {
    if (offBoardingCompleteNotification !== null) {
      setOffboardingCompleteNotification(null);
    }
  }, [offBoardingCompleteNotification]);

  useEffect(() => {
    socket.channel("Syncing").listen("SyncAllNotification", (e) => {
      setSyncAllNotification(e.data);
    });

    socket.channel("Syncing").listen("SyncAllCompleteNotification", (e) => {
      setSyncAllCompleteNotification(e.data);
    });

    socket.channel("OnBoarding").listen("OnboardingNotification", (e) => {
      setOnBoardingNotification(e.data[0]);
    });

    socket
      .channel("OnBoarding")
      .listen("OnboardingCompleteNotification", (e) => {
        setOnboardingCompleteNotification(e.data);
      });

    socket.channel("OffBoarding").listen("OffboardingNotification", (e) => {
      setOffboardingNotification(e.data);
    });

    socket
      .channel("OffBoarding")
      .listen("OffboardingCompleteNotification", (e) => {
        setOffboardingCompleteNotification(e.data);
      });
  }, []);

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        index
        path="/"
        element={
          <Layout>
            <Websites
              syncAllNotification={syncAllNotification}
              syncAllCompleteNotification={syncAllCompleteNotification}
            />
          </Layout>
        }
      />
      <Route
        path="users"
        element={
          <Layout>
            <Users
              offBoardingNotification={offBoardingNotification}
              offBoardingCompleteNotification={offBoardingCompleteNotification}
            />
          </Layout>
        }
      />
      <Route
        path="user/:id"
        element={
          <Layout>
            <UserEdit />
          </Layout>
        }
      />
      <Route
        path="user/add"
        element={
          <Layout>
            <AddUser
              onBoardingNotification={onBoardingNotification}
              onBoardingCompleteNotification={onBoardingCompleteNotification}
            />
          </Layout>
        }
      />
      <Route
        path="team"
        element={
          <Layout>
            <Team />
          </Layout>
        }
      />
      <Route
        path="profile"
        element={
          <Layout>
            <Profile />
          </Layout>
        }
      />
      <Route
        path="user-management"
        element={
          <Layout>
            <UserManagement />
          </Layout>
        }
      />
      <Route
        path="integration/add_site"
        element={
          <Layout>
            <AddSite />
          </Layout>
        }
      />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      <Route path="login" element={<Login />} />


    </Routes>
  );
}

export default App;
