import { useState } from "react";
import { useAuthContext } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "../../api/AxiosHttp";
import Cookies from "js-cookie";
import Noty from "../../lib/Noty";

const Login = () => {
  const { getUserData } = useAuthContext();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [twoFA, setTwoFA] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios.get("/sanctum/csrf-cookie").then(() => {
      axios
        .post("/api/login", { email, password })
        .then(({ data }) => {
          if (data?.two_factor === true) {
            setTwoFA(true);
          } else {
            Cookies.set("user_management_is_user_logged_in", true, {
              expires: 86400,
              sameSite: "lax",
            });

            getUserData();

            navigate("/");
          }
        })
        .catch(function (error) {
          if (error.response) {
            var er = error.response.data.errors;
            var notymessage = er[Object.keys(er)[0]];

            new Noty({
              type: "error",
              text: notymessage,
            }).show();
          }
        });
    });
  };

  const handleCode = async (e) => {
    e.preventDefault();

    axios
      .post("api/two-factor-challenge", { code })
      .then(({ data }) => {
        console.log(data)
        Cookies.set("user_management_is_user_logged_in", true, {
          expires: 86400,
          sameSite: "lax",
        });

        getUserData();

        navigate("/");
      })
      .catch(function (error) {
        if (error.response) {
          var er = error.response.data.errors;
          var notymessage = er[Object.keys(er)[0]];

          new Noty({
            type: "error",
            text: notymessage,
          }).show();
        }
      });
  };

  return (
    <div className="flex flex-col md:flex-row h-screen items-center">
      <div className="bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-3/4 h-screen">
        <img
          src="https://source.unsplash.com/random"
          alt=""
          className="w-full h-full object-cover"
        />
      </div>

      <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
        <div className="w-full h-100">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/proactive-logo.svg`}
            alt="Proactive Investors"
            className="w-40"
          ></img>

          <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12">
            Log in to your account
          </h1>

          {!twoFA ? (
            <form className="mt-6" onSubmit={handleSubmit}>
              <div>
                <label className="block text-gray-700">Email Address</label>
                <input
                  type="email"
                  name=""
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Enter Email Address"
                  className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                  autoFocus
                  autoComplete="true"
                  required
                />
              </div>

              <div className="mt-4">
                <label className="block text-gray-700">Password</label>
                <input
                  type="password"
                  name=""
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  placeholder="Enter Password"
                  className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                  minLength="6"
                  required
                />
                <Link to="/forgot-password">
                  <a>
                    <label className="block text-gray-700 text-right">
                      Forgot Password?
                    </label>
                  </a>
                </Link>
              </div>

              <button
                type="submit"
                className="w-full block bg-orange-500 hover:bg-orange-400 focus:bg-orange-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
              >
                Log In
              </button>
            </form>
          ) : (
            <form className="mt-6" onSubmit={handleCode}>
              <div>
                <label className="block text-gray-700">2FA Code</label>
                <input
                  type="number"
                  name=""
                  onChange={(e) => setCode(e.target.value)}
                  value={code}
                  placeholder="Enter Code"
                  className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                  minLength="6"
                  required
                />
              </div>

              <button
                type="submit"
                className="w-full block bg-orange-500 hover:bg-orange-400 focus:bg-orange-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
              >
                Verify
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
