import { useState, useEffect, useRef } from "react";
import axios from "../../api/AxiosHttp";
import { useAuthContext } from "../../context/AuthProvider";
import WebsiteTable from "../../components/Table/Websites";
import Noty from "../../lib/Noty";
import Card from "../../components/Card";
import AddWebsite from "./AddWebsite";

const Websites = ({ syncAllNotification, syncAllCompleteNotification }) => {
  const { activeOrganisation } = useAuthContext();

  const [sites, setSites] = useState([]);
  const [updatedKey, setUpdatedKey] = useState(Date.now());

  const counter = useRef(0);
  const totalCounter = useRef(0);

  useEffect(() => {
    if (!syncAllNotification) return;

    console.log(syncAllNotification, "syncAllNotification");
    var type = syncAllNotification.error ? "error" : "success";
    var text = syncAllNotification.error
      ? syncAllNotification.error
      : syncAllNotification[0].website.url + " was successfully synced";
    var syncAllMessage = new Noty({
      type: type,
      text: text,
    }).show();

    counter.current = ++counter.current;

    new Noty({
      text:
        "Syncing is running in the background. " +
        counter.current +
        "/" +
        totalCounter.current,
      type: "info",
      timeout: false,
      modal: true,
      queue: "syncAll",
      killer: "syncAll",
      force: true,
      animation: {
        open: null,
        close: null,
      },
    }).show();
  }, [syncAllNotification]);

  useEffect(() => {
    if (!syncAllCompleteNotification) return;

    console.log(syncAllCompleteNotification, "syncAllCompleteNotification");

    if (syncAllCompleteNotification.message) {
      getSites();
      // setSites(syncAllCompleteNotification.websites);
      //setUpdatedKey(Date.now());

      counter.current = 0;

      setTimeout(() => {
        new Noty({
          text: syncAllCompleteNotification.message,
          type: "success",
          timeout: 3000,
          modal: true,
          queue: "syncAll",
          killer: "syncAll",
          progressBar: true,
          force: true,
          animation: {
            open: null,
          },
        }).show();
      }, 3000);

    }
  }, [syncAllCompleteNotification]);

  useEffect(() => {
    getSites();
  }, [activeOrganisation]);

  const getSites = async () => {
    if (activeOrganisation) {
      const endPoint = "/api/websites/org/" + activeOrganisation;
      await axios.get(endPoint).then((result) => {
        console.log(result, "getSites()");
        setSites(result.data);
        setUpdatedKey(Date.now());
      });
    }
  };

  const syncAllSites = async () => {
    if (activeOrganisation) {
      const endPoint = "/api/websites/sync_all";

      axios
        .post(endPoint, { organisation_id: activeOrganisation })
        .then((result) => {
          totalCounter.current = result.data.counter;

          new Noty({
            text:
              "Syncing is running in the background. 1/" + result.data.counter,
            type: "info",
            timeout: false,
            modal: true,
            queue: "syncAll",
            killer: "syncAll",
            force: true,
            animation: {
              close: null,
            },
          }).show();

          console.log(result);
        });
    }
  };

  return (
    <>
      <div className="w-full">
        <AddWebsite/>
        <Card>
          <h3 className="text-xl mb-4 font-bold leading-none text-gray-900">
            Manage Sites
          </h3>
          <p className="block text-md font-light mb-4" htmlFor="url">
            Manage all the sites you've added
          </p>

          {/* No longer needed as we update table via socket */}
          {/* <button
            onClick={getSites}
            className="mb-6 hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center mr-3"
            type="button"
          >
            Refresh Data
          </button> */}

          <button
            onClick={syncAllSites}
            className="mb-6 hidden sm:inline-flex text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center mr-3"
            type="button"
          >
            Sync Sites
          </button>

          <div className="border border-gray-200 rounded overflow-x-auto min-w-full bg-white">
            {sites.length > 0 && (
              <WebsiteTable key={updatedKey} sites={sites} />
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default Websites;
