import Echo from "laravel-echo";
window.Pusher = require("pusher-js");

export default window.Echo = new Echo({
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_KEY,
  cluster: process.env.REACT_APP_WS_CLUSTER,
  // wsHost: process.env.REACT_APP_WS_HOST,
  // wsPort: process.env.REACT_APP_WS_PORT,
  forceTLS: true
});